<template>
  <app-page>
    <div class="form-container">
      <block-title>产品管理</block-title>
      <el-form
        v-loading="loading"
        ref="mainForm"
        :model="formData"
        :label-width="110"
        class="product-form"
        @submit.prevent="handleSubmit"
      >
        <el-form-item
          label="产品类型"
          prop="type"
          :rules="{ required: true, message: '请输入产品类型' }"
        >
          <el-input
            v-model.trim="formData.type"
            :maxlength="8"
            show-word-limit
            placeholder="请填写产品类型"
          />
        </el-form-item>
        <el-form-item
          label="产品说明"
          prop="description"
          :rules="{ required: true, message: '请输入产品说明' }"
        >
          <el-input
            v-model.trim="formData.description"
            :maxlength="24"
            show-word-limit
            placeholder="请填写产品说明"
          />
        </el-form-item>
        <el-form-item
          label="发放记录格式"
          prop="businessType"
          :rules="{ required: true, message: '请选择发放记录格式' }"
        >
          <el-radio-group v-model="formData.businessType" >
            <el-radio v-for="item in Object.keys(proBusinessTypeEnum)" :key="item" :label="item">{{ proBusinessTypeEnum[item].dataValue }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <collapsable-items-group
          v-for="module in modules"
          :key="module.key"
          :label="module.label"
          :expended="currentModuleKey === module.key"
          :enabled="formData[module.key] && formData[module.key].disabled !== void(0) ? !formData[module.key].disabled : void(0)"
          @update:extended="handleUpdateExtended(module.key, $event)"
          @update:enabled="handleUpdateEnabled(formData[module.key], $event)"
        >
          <template v-if="module.key === 'basicSetting'">
            <product-module-basic />
          </template>
          <template v-else-if="module.key === 'account'">
            <el-form-item
              v-for="item in ['账户户名', '银行账号', '开户银行', '结算产品']"
              :key="item"
              :label="item"
            />
          </template>
          <product-module
            v-else
            :module-key="module.key"
            :model="formData[module.key].options"
          />
        </collapsable-items-group>

        <el-divider />
        <el-form-item>
          <el-button
            type="primary"
            native-type="submit"
            :loading="submitting"
          >保存</el-button>
          <el-button @click="$router.back()">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, ref, computed, toRaw } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import type { Product } from '@/types/product'
import defaultProduct from './defaultProduct'
import CollapsableItemsGroup from './components/CollapsableItemsGroup.vue'
import ProductModule from './components/ProductModule.vue'
import ProductModuleBasic from './components/ProductModuleBasic.vue'
import { toProductFormData, formatProduct } from './utils'
import { addProduct, updateProduct, getProductDetail } from '@/api/product'
import { useStore } from 'vuex'

function createDefaultProduct(): Product {
  return JSON.parse(JSON.stringify(defaultProduct))
}

const labelMap = {
  basicSetting: '基础配置',
  infoSetting: '信息配置',
  riskManagement: '风控配置',
  // socialSecurity: '社保公积金',
  // settlement: '结算配置',
  // account: '账户配置',
  other: '其他配置'
}

export default defineComponent({
  name: 'ProductForm',
  components: {
    CollapsableItemsGroup,
    ProductModule,
    ProductModuleBasic
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const submitting = ref(false)
    const proBusinessTypeEnum = store.state.enumData.enumInfo.pro_business_type
    // 表单
    // --------------------------------------------------------------
    const mainForm = ref()
    const formData = ref<Product>(createDefaultProduct())

    const modules = computed(() => (
      Object.keys(labelMap)
        .filter(
          key =>
            key === 'basicSetting' ||
            key === 'account' ||
            (key in formData.value && !formData.value[key].hidden)
        )
        .map(key => ({ key, label: labelMap[key] }))
    ))

    const handleSubmit = () => {
      // 校验提交
      mainForm.value.validate((valid, cols) => {
        if (valid) {
          submit(toProductFormData(toRaw(formData.value)))
        } else {
          const invalidCols = Object.keys(cols).map(col => col.split('.')[0])
          const extended = invalidCols.findIndex(field => field === currentModuleKey.value)
          if (extended < 0) {
            currentModuleKey.value = invalidCols.find(c => !!labelMap[c]) || null
          }
          ElMessage.error('表单未填写完整')
        }
      })
    }
    const submit = async (data) => {
      submitting.value = true
      const func = data.id ? updateProduct : addProduct
      const res = await func(data)
      submitting.value = false
      if (res.success) {
        ElMessage.success('保存成功')
        router.push({ name: 'accounts_products_products' })
      }
    }

    // 数据回显
    const { id } = route.query
    const loadDetail = async () => {
      loading.value = true
      const res = await getProductDetail({ id: Number(id) })
      loading.value = false
      if (res.success && res.result) {
        formData.value = formatProduct(res.result)
      }
    }
    if (id) {
      loadDetail()
    }

    // 展开
    // --------------------------------------------------------------
    const currentModuleKey = ref<string | null>('basicSetting')
    const handleUpdateExtended = (moduleKey, extended) => {
      const module = formData.value[moduleKey] || {}
      // 未禁用
      if (module.disabled !== true) {
        currentModuleKey.value = (extended ? moduleKey : null)
      }
    }

    // 禁用
    // --------------------------------------------------------------
    const handleUpdateEnabled = (module, enabled: boolean) => {
      if (module.disabled !== undefined) {
        module.disabled = !enabled
      }
    }

    return {
      loading,
      submitting,
      modules,
      mainForm,
      formData,
      proBusinessTypeEnum,
      handleSubmit,
      currentModuleKey,
      handleUpdateExtended,
      handleUpdateEnabled
    }
  }
})
</script>
<style lang="less" scoped>
.product-form {
  :deep(.el-form-item) {
    margin-bottom: 10px;

    &__error {
      position: static;
    }
  }
}
</style>
