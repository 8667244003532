<template>
  <el-dialog
    v-model="visible"
    title="账户配置"
    width="500px"
  >
    <el-form
      ref="mainForm"
      :model="formData"
      class="service-provider-form"
      name="service_provider_form"
      id="service_provider_form"
      label-width="6em"
      @submit.prevent="handleSubmit"
    >
      <el-form-item
        label="服务团队"
        prop="teamId"
        :rules="{ required: true, message: '请选择服务商企业全称' }"
      >
        <el-select
          v-model="formData.teamId"
          clearable
          placeholder="请选择服务商企业全称"
          :disabled="formdisabled"
        >
          <el-option
            v-for="item in serverList"
            :key="item.id"
            :label="item.teamName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="服务产品"
        prop="sysServiceId"
        :rules="{ required: true, message: '请选择可使用的第三方服务' }"
      >
        <el-select
          v-model="formData.sysServiceId"
          clearable
          :disabled="true"
          placeholder="请选择可使用的第三方服务"
        >
          <el-option
            v-for="item in serverProductList"
            :key="item.id"
            :label="item.service_name"
            :value="item.id.toString()"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="新增数量"
        prop="addNum"
        :rules="addNumRules"
      >
        <div v-if="currentTarget && currentTarget.enable_num" class="inline-input">
          <el-input
            v-model.number="formData.addNum"
            :disabled="!currentTarget"
            :placeholder="`最大可分配数量${currentTarget.enable_num}`"
          />
          <div class="rest-count">
            剩余数量：{{ restCount }}
          </div>
        </div>
        <el-input
          v-else
          v-model.number="formData.addNum"
          placeholder="请配置新增用量"
          :disabled="!currentTarget"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button
        type="primary"
        native-type="submit"
        form="service_provider_form"
        :loading="loading"
      >确认新增</el-button>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  reactive,
  ref,
  PropType,
  watch
} from 'vue'
import { ElMessage } from 'element-plus'
import { getServicerTeam, getServicerList, addServicerTeam } from '@/api/servicer'
import type { ServerSetRow } from '@/api/model/servicerModel'

export default defineComponent({
  name: 'AddAccountDialog',
  emits: ['success'],
  props: {
    accountId: {
      type: Number,
      required: true
    },
    sysServiceId: {
      type: String as PropType<string>,
      required: true
    },
    teamId: {
      type: String as PropType<string>,
      required: false
    }
  },
  setup(props, { emit }) {
    const visible = ref(false)
    // 是否只读
    const formdisabled = computed(() => !!props.teamId)
    const currentTarget = computed(() => (
      serverProductList.value && serverProductList.value.find(
        item => formData.sysServiceId === item.id.toString()
      )
    ))
    // 获取服务商列表
    const serverList = ref()
    const getServicerTeamData = async() => {
      const res = await getServicerTeam()
      if (res.success && res.result) {
        serverList.value = res.result
      }
    }
    // 获取可用的服务商
    const serverProductList = ref()
    const getServicerData = async() => {
      const res = await getServicerList({
        // status: '1',
        pageNo: 1,
        pageSize: 99999
      })
      if (res.success && res.result) {
        serverProductList.value = res.result.data
      }
    }
    // 剩余数量
    const restCount = computed(() => {
      const addNum = Number(formData.addNum)
      return (
        currentTarget.value &&
        currentTarget.value.enable_num &&
        currentTarget.value.enable_num >= addNum
          ? currentTarget.value.enable_num - addNum
          : ''
      )
    })
    // 表单提交
    // ------------------------------------------------
    const addNumRules = computed(() => {
      const rules = [{ type: 'number', message: '必须是数字' }] as any[]
      if (currentTarget.value && currentTarget.value.enable_num) {
        rules.push({
          type: 'number',
          max: currentTarget.value.enable_num,
          message: '超过最大可分配数量'
        })
      }
      return rules
    })
    const mainForm = ref()
    const loading = ref(false)
    const formData = reactive<ServerSetRow>({
      sysServiceId: props.sysServiceId,
      addNum: '',
      teamId: ''
    })
    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      const data = {
        id: props.accountId,
        ...formData
      }
      loading.value = true
      const res = await addServicerTeam(data)
      loading.value = false
      if (res.success) {
        visible.value = false
        ElMessage.success('添加成功')
        emit('success')
      }
    }
    watch(visible, (visible) => {
      visible && getServicerTeamData() && getServicerData()
      if (visible && props.teamId) {
        formData.teamId = props.teamId
      } else if (!visible) {
        formData.addNum = ''
        formData.teamId = ''
      }
    })
    watch(props, (visible) => {
      if (visible) {
        formData.teamId = props.teamId
      }
    },
    {
      deep: true
    })
    const temepList = ref([])
    return {
      visible,
      loading,
      formData,
      addNumRules,
      restCount,
      mainForm,
      serverList,
      serverProductList,
      formdisabled,
      currentTarget,
      handleSubmit,
      temepList
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
})
</script>
<style lang="less" scoped>
.service-provider-form {
  :deep(.el-select) {
    width: 100%;
  }

  .inline-input {
    display: flex;

    .el-input {
      width: 220px;
    }

    .rest-count {
      margin-left: 10px;
    }
  }
}
</style>
