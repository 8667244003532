
import {
  computed,
  defineComponent,
  reactive,
  ref,
  PropType,
  watch
} from 'vue'
import { ElMessage } from 'element-plus'
import { getServicerTeam, getServicerList, addServicerTeam } from '@/api/servicer'
import type { ServerSetRow } from '@/api/model/servicerModel'

export default defineComponent({
  name: 'AddAccountDialog',
  emits: ['success'],
  props: {
    accountId: {
      type: Number,
      required: true
    },
    sysServiceId: {
      type: String as PropType<string>,
      required: true
    },
    teamId: {
      type: String as PropType<string>,
      required: false
    }
  },
  setup(props, { emit }) {
    const visible = ref(false)
    // 是否只读
    const formdisabled = computed(() => !!props.teamId)
    const currentTarget = computed(() => (
      serverProductList.value && serverProductList.value.find(
        item => formData.sysServiceId === item.id.toString()
      )
    ))
    // 获取服务商列表
    const serverList = ref()
    const getServicerTeamData = async() => {
      const res = await getServicerTeam()
      if (res.success && res.result) {
        serverList.value = res.result
      }
    }
    // 获取可用的服务商
    const serverProductList = ref()
    const getServicerData = async() => {
      const res = await getServicerList({
        // status: '1',
        pageNo: 1,
        pageSize: 99999
      })
      if (res.success && res.result) {
        serverProductList.value = res.result.data
      }
    }
    // 剩余数量
    const restCount = computed(() => {
      const addNum = Number(formData.addNum)
      return (
        currentTarget.value &&
        currentTarget.value.enable_num &&
        currentTarget.value.enable_num >= addNum
          ? currentTarget.value.enable_num - addNum
          : ''
      )
    })
    // 表单提交
    // ------------------------------------------------
    const addNumRules = computed(() => {
      const rules = [{ type: 'number', message: '必须是数字' }] as any[]
      if (currentTarget.value && currentTarget.value.enable_num) {
        rules.push({
          type: 'number',
          max: currentTarget.value.enable_num,
          message: '超过最大可分配数量'
        })
      }
      return rules
    })
    const mainForm = ref()
    const loading = ref(false)
    const formData = reactive<ServerSetRow>({
      sysServiceId: props.sysServiceId,
      addNum: '',
      teamId: ''
    })
    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      const data = {
        id: props.accountId,
        ...formData
      }
      loading.value = true
      const res = await addServicerTeam(data)
      loading.value = false
      if (res.success) {
        visible.value = false
        ElMessage.success('添加成功')
        emit('success')
      }
    }
    watch(visible, (visible) => {
      visible && getServicerTeamData() && getServicerData()
      if (visible && props.teamId) {
        formData.teamId = props.teamId
      } else if (!visible) {
        formData.addNum = ''
        formData.teamId = ''
      }
    })
    watch(props, (visible) => {
      if (visible) {
        formData.teamId = props.teamId
      }
    },
    {
      deep: true
    })
    const temepList = ref([])
    return {
      visible,
      loading,
      formData,
      addNumRules,
      restCount,
      mainForm,
      serverList,
      serverProductList,
      formdisabled,
      currentTarget,
      handleSubmit,
      temepList
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
})
