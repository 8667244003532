<template>
  <el-dialog
    v-model="visible"
    title="新增服务商"
    width="500px"
  >
    <el-form
      ref="mainForm"
      :model="formData"
      class="service-provider-form"
      name="service_provider_form"
      id="service_provider_form"
      @submit.prevent="handleSubmit"
    >
      <el-form-item prop="teamName" :rules="{ required: true, message: '请输入服务商企业全称' }">
        <el-input v-model="formData.teamName" placeholder="请输入服务商企业全称" />
      </el-form-item>
      <el-form-item prop="userName" :rules="{ required: true, message: '请输入管理员称呼' }">
        <el-input v-model="formData.userName" placeholder="请输入管理员称呼" />
      </el-form-item>
      <el-form-item prop="mobile" :rules="{ required: true, message: '请输入管理员手机号码' }">
        <el-input v-model="formData.mobile" maxlength="11" placeholder="请输入管理员手机号码" />
      </el-form-item>
      <el-form-item prop="service">
        <el-select
          v-model="formData.service"
          multiple
          placeholder="请选择可使用的第三方服务（可多选）"
        >
          <el-option
            v-for="item in serverProductList"
            :label="item.service_name"
            :key="item.id"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="token">
        <el-input v-model="formData.token" placeholder="请输入钉钉Token" />
      </el-form-item>
      <el-form-item prop="aesKey">
        <el-input v-model="formData.aesKey" placeholder="请输入钉钉aesKey" />
      </el-form-item>
      <el-form-item prop="suiteKey">
        <el-input v-model="formData.suiteKey" placeholder="请输入钉钉suiteKey" />
      </el-form-item>
      <el-form-item prop="appKey">
        <el-input v-model="formData.appKey" placeholder="请输入钉钉appKey" />
      </el-form-item>
      <el-form-item prop="appSecret">
        <el-input v-model="formData.appSecret" placeholder="请输入钉钉appSecret" />
      </el-form-item>
      <el-form-item prop="processCode">
        <el-input v-model="formData.processCode" placeholder="请输入钉钉流程模板id" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button
        type="primary"
        native-type="submit"
        form="service_provider_form"
        :loading="loading"
      >确认新增</el-button>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  watch
} from 'vue'
import { ElMessage } from 'element-plus'
import { addAccount } from '@/api/account'
import type { Account2 } from '@/api/model/accountModel'
import { getServicerList } from '@/api/servicer'

type AppAccount = Omit<Account2, 'teamDingConfigDTO'> & Account2['teamDingConfigDTO']

export default defineComponent({
  name: 'AddAccountDialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)

    // 表单提交
    // ------------------------------------------------
    const mainForm = ref()
    const loading = ref(false)
    const formData = reactive<AppAccount>({
      userName: '',
      teamName: '',
      mobile: '',
      service: [],
      token: '',
      aesKey: '',
      suiteKey: '',
      appKey: '',
      appSecret: '',
      processCode: ''
    })
    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      loading.value = true
      const {
        id,
        userName,
        teamName,
        mobile,
        service,
        token,
        aesKey,
        suiteKey,
        appKey,
        appSecret,
        processCode
      } = formData
      const data: Account2 = {
        id,
        userName,
        teamName,
        mobile,
        service,
        teamDingConfigDTO: {
          token,
          aesKey,
          suiteKey,
          appKey,
          appSecret,
          processCode
        }
      }
      const res = await addAccount(data)
      loading.value = false
      if (res.success) {
        visible.value = false
        ElMessage.success('添加成功')
        emit('success')
      }
    }

    watch(visible, (visible) => {
      visible && getServicerData()
    })

    // 获取可用的服务商
    const serverProductList = ref()
    const getServicerData = async() => {
      const res = await getServicerList({
        status: '1',
        pageNo: 1,
        pageSize: 99999
      })
      if (res.success && res.result) {
        serverProductList.value = res.result.data
      }
    }
    return {
      visible,
      serverProductList,
      loading,
      formData,
      mainForm,
      handleSubmit
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
})
</script>
<style lang="less" scoped>
.service-provider-form {
  :deep(.el-select) {
    width: 100%;
  }
}
</style>
