// 占位符分类枚举
export const categoryEnum = {
  0: '企业信息',
  1: '个人信息',
  2: '其他信息'
}

// 占位符分类枚举
export const placeholderTypeEnum = {
  0: '文本框'
}
