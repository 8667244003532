
import {
  defineComponent,
  reactive,
  ref,
  watch
} from 'vue'
import { ElMessage } from 'element-plus'
import { addAccount } from '@/api/account'
import type { Account2 } from '@/api/model/accountModel'
import { getServicerList } from '@/api/servicer'

type AppAccount = Omit<Account2, 'teamDingConfigDTO'> & Account2['teamDingConfigDTO']

export default defineComponent({
  name: 'AddAccountDialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)

    // 表单提交
    // ------------------------------------------------
    const mainForm = ref()
    const loading = ref(false)
    const formData = reactive<AppAccount>({
      userName: '',
      teamName: '',
      mobile: '',
      service: [],
      token: '',
      aesKey: '',
      suiteKey: '',
      appKey: '',
      appSecret: '',
      processCode: ''
    })
    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      loading.value = true
      const {
        id,
        userName,
        teamName,
        mobile,
        service,
        token,
        aesKey,
        suiteKey,
        appKey,
        appSecret,
        processCode
      } = formData
      const data: Account2 = {
        id,
        userName,
        teamName,
        mobile,
        service,
        teamDingConfigDTO: {
          token,
          aesKey,
          suiteKey,
          appKey,
          appSecret,
          processCode
        }
      }
      const res = await addAccount(data)
      loading.value = false
      if (res.success) {
        visible.value = false
        ElMessage.success('添加成功')
        emit('success')
      }
    }

    watch(visible, (visible) => {
      visible && getServicerData()
    })

    // 获取可用的服务商
    const serverProductList = ref()
    const getServicerData = async() => {
      const res = await getServicerList({
        status: '1',
        pageNo: 1,
        pageSize: 99999
      })
      if (res.success && res.result) {
        serverProductList.value = res.result.data
      }
    }
    return {
      visible,
      serverProductList,
      loading,
      formData,
      mainForm,
      handleSubmit
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
})
