
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import ConfigSearchForm from './components/ConfigSearchForm.vue'
import { getPlaceSettingList, changeServicerStatus } from '@/api/servicer'
import { useStore } from 'vuex'
import { dateFormat } from '@/utils/date'

// List
const fields = [
  { label: '编号', prop: 'id', minWidth: 50 },
  { label: '配置名称', prop: 'name' },
  { label: '适用范围', prop: 'applyTo', minWidth: 100 },
  { label: '更新时间', prop: 'updatedAt', minWidth: 100 },
  { label: '备注说明', prop: 'remark', minWidth: 80 }
]

export default defineComponent({
  name: 'ConfigList',
  // components: { ConfigSearchForm },
  setup() {
    const loading = ref<boolean>(false)
    // 服务类型
    const store = useStore()
    const serviceEnum = store.state.enumData.enumInfo.service
    // 服务列表
    // -------------------------------
    interface configSet {
      keyword?: string
    }
    const params = reactive<configSet>({})
    const rows = ref()
    const loadList = async () => {
      loading.value = true
      const res = await getPlaceSettingList(params)
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result
      }
    }
    loadList()

    // 搜索
    const handleSearch = () => {
      loadList()
    }

    const formatCol = (row, col) => {
      if (col.property === 'created_at') {
        return dateFormat(row.updatedAt)
      }
      return row[col.property]
    }
    return {
      loading,
      params,
      fields,
      rows,
      formatCol,
      handleSearch,
      serviceEnum
    }
  }
})
