
import { defineComponent, PropType, computed } from 'vue'

export default defineComponent({
  name: 'CollapsableItemsGroup',
  emits: ['update:extended', 'update:enabled', 'toggle-expend'],
  props: {
    label: String as PropType<string>,
    enabled: Boolean as PropType<boolean>,
    expended: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup(props, { emit }) {
    // 展开
    // ----------------------------------------------------------
    const toggleExpended = () => {
      emit('update:extended', !props.expended)
      emit('toggle-expend', !props.expended)
    }
    const isExpended = computed(() => props.enabled !== false && props.expended)

    return {
      isExpended,
      toggleExpended
    }
  }
})
