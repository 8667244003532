
import { defineComponent, PropType, computed } from 'vue'

export default defineComponent({
  name: 'RangeInput',
  props: {
    modelValue: Array as PropType<Array<string>>,
    disabled: Boolean as PropType<boolean>
  },
  setup(props, { emit }) {
    const getter = (index: number) => {
      return props.modelValue ? (props.modelValue[index] || '') : ''
    }
    const setter = (index: number, val) => {
      let v = ['', '']
      if (props.modelValue) {
        v = props.modelValue.slice()
        v[index] = val
      }
      emit('update:modelValue', v)
    }
    const firstValue = computed({
      get() {
        return getter(0)
      },
      set(val) {
        setter(0, val)
      }
    })
    const lastValue = computed({
      get() {
        return getter(1)
      },
      set(val) {
        setter(1, val)
      }
    })
    return {
      firstValue,
      lastValue
    }
  }
})
