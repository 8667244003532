<template>
  <el-form inline v-model="formData" @submit.prevent="handleSubmit">
    <el-form-item label="客户名称" prop="name">
      <el-input
        v-model.trim="formData.name"
        placeholder="客户名称、地区、编号等"
        style="width: 160px"
      />
    </el-form-item>
    <el-form-item label="客户类型" prop="type">
      <el-select v-model="formData.type" style="width: 100px">
        <el-option label="服务商" :value="1"/>
      </el-select>
    </el-form-item>
    <el-form-item label="客户状态" prop="status">
      <el-select v-model="formData.status" style="width: 100px">
        <el-option label="全部" value="" />
        <el-option label="待生效" :value="0" />
        <el-option label="生效中" :value="1" />
      </el-select>
    </el-form-item>
    <el-form-item label="创建时间" prop="created">
      <el-date-picker
        v-model="formData.created"
        type="daterange"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        style="width: 220px"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" native-type="submit" icon="el-icon-search">搜索</el-button>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { defineComponent, PropType, toRef } from 'vue'
import { customerTypes, customerStates } from '@/config/enums/account'
// import { dateFormat } from '@/utils/date'
import type { AccountListParams } from '../types'
// import type { AccountListParams as ApiAccountListParams } from '@/api/model/accountModel'

export default defineComponent({
  name: 'AccountSearchForm',
  emits: ['submit'],
  props: {
    modelValue: {
      type: Object as PropType<AccountListParams>,
      required: true
    }
  },
  setup(props, { emit }) {
    // const formData = shallowReactive({
    //   name: '',
    //   type: 1,
    //   status: '',
    //   created: []
    // })
    const formData = toRef(props, 'modelValue')

    const handleSubmit = () => {
      // const data: ApiAccountListParams = {}
      // const { name, type, status, created } = formData
      // if (name) {
      //   data.name = name
      // }
      // if (type) {
      //   data.type = Number(type)
      // }
      // if (status !== '') {
      //   data.status = Number(status)
      // }
      // if (created && created.length > 0) {
      //   const [startDate, endDate] = (created as Date[]).map(date => dateFormat(date, 'YY-MM-dd'))
      //   data.startDate = startDate
      //   data.endDate = endDate
      // }
      emit('submit', formData.value)
    }

    return {
      customerTypes,
      customerStates,
      formData,
      handleSubmit
    }
  }
})
</script>
