/**
 * 产品相关工具类
 */
import type { Product } from '@/types/product'
import type { Product as ApiProduct } from '@/api/model/productModel'
import defaultProduct from './defaultProduct'

// 信息配置
const infoMap = {
  basicInfo: 'basicInfoDto', // 基础信息
  idCard: 'cardPhoto', // 身份证图片
  degreeDiploma: 'academic', // 学位证书
  diploma: 'graduation', // 毕业证
  bankCard: 'fontAndBack', // 银行卡正反面
  leavingCertificate: 'leavingProve', // 离职证明
  wageDetail: 'wageFlow' // 工资流水
}

// 风控配置
const riskManagementMap = {
  contractCheck: 'isContract', // 合同校验
  orcCheck: 'isCardIdPhoto', // OCR校验
  livingCheck: 'isLiving', // 活体校验
  ageLimit: 'isAge', // 年龄限制
  infoCheck: 'isInfo', // 信息校验
  orderTaking: 'isReceipt', // 接单校验
  dispensingCheck: 'isGrant' // 发放校验
}

// 社保公积金
const socialSecurityMap = {
  location: 'placeOfPay', // 缴纳地
  base: 'base', // 社保基数
  individualProportion: 'indPro', // 个人缴纳比例
  employerProportion: 'comPro', // 公司缴纳比例
  accumulationFundBase: 'accumFundBase', // 公积金基数
  accumulationFundIndividualProportion: 'accumFundIndPro', // 个人缴纳比例
  accumulationFundEmployerProportion: 'accumFundComPro' // 公司缴纳比例
}

// 结算配置
const settlementMap = {
  valueAddedTax: 'addTax', // 增值税
  individualIncomeTax: 'vat', // 个人所得税
  serviceFee: 'serCharge', // 服务费
  otherFee: 'otherCharge', // 其他费用
  method: 'settingWay', // 结算方式
  period: 'setCycle', // 结算周期
  template: 'setModel' // 结算模板
}

// 其他配置
const otherMap = {
  annualCapitalLimit: 'yearFlowQuota', // 年流水限额
  annualTaxlLimit: 'yearTaxesQuota', // 年纳税限额
  invoicingLimit: 'yearInvoiceQuota', // 开票额限制
  invoiceType: 'invoiceType', // 发票类型
  distributionLimit: 'grantQuota', // 发放限额
  taxLimit: 'taxesQuota' // 纳税限额
}

const formatters = {
  infoSetting: {
    sourceKey: 'infoSet',
    map: infoMap
  },
  riskManagement: {
    sourceKey: 'safeSet',
    map: riskManagementMap
  },
  // socialSecurity: {
  //   sourceKey: 'socialSecurity',
  //   map: socialSecurityMap
  // },
  // settlement: {
  //   sourceKey: 'settle',
  //   map: settlementMap
  // },
  other: {
    sourceKey: 'other',
    map: otherMap
  }
}

// 将后端返回的产品数据类型转换为前端格式
export function formatProduct(source: ApiProduct): Product {
  const product: Product = JSON.parse(JSON.stringify(defaultProduct))
  if (source.id) {
    product.id = source.id
  }
  product.type = source.name
  product.description = source.remark
  product.businessType = !source.businessType ? '' : source.businessType.toString()
  if (!source.list) {
    source.list = []
  }
  for (const p in formatters) {
    const module = product[p]
    const { sourceKey, map } = formatters[p]
    const sourceModule = source.list.find(({ settingKind }) => settingKind === sourceKey)
    if (sourceModule) {
      const { id, info } = sourceModule
      module.id = id
      let sourceOptions
      try {
        sourceOptions = JSON.parse(info)
      } catch (e) {
        console.log('parse json error')
      }
      if (sourceOptions) {
        for (const k in module.options) {
          const sourceOptionKey = map[k]
          const so = sourceOptions[sourceOptionKey]
          if (so) {
            const options = module.options[k]
            options.setting = so.setting
            if (options.type === 'required') {
              options.value = so.required === 1
            } else if (options.type === 'ageLimit') {
              options.value = {
                required: so.required === 1,
                minAge: so.minAge || '',
                maxAge: so.maxAge || ''
              }
              // options.value.whiteList = so.whiteList === 1
            } else if (options.type === 'infoCheck') {
              options.value = so.required || 0
            }
          }
        }
      }
      module.disabled = false
    } else {
      module.disabled = true
    }
  }
  return product
}

// 将前端格式转换为后端格式
export function toProductFormData(product: Product) {
  const formData: any = {}
  if (product.id) {
    formData.id = product.id
  }
  formData.productType = product.type
  formData.describe = product.description
  formData.businessType = product.businessType
  for (const p in formatters) {
    const module = product[p]
    const { sourceKey, map } = formatters[p]
    if (module.disabled) {
      formData[sourceKey] = null
    } else {
      const { options } = module
      const sourceModule: any = {}
      if (module.id) {
        sourceModule.id = module.id
      }
      for (const k in map) {
        const { type, setting, value } = options[k]
        const sourceOptionKey = map[k]
        const sourceOptions = sourceModule[sourceOptionKey] = { setting } as any
        if (type === 'required') {
          sourceOptions.required = (value ? 1 : 0)
        } else if (type === 'ageLimit') {
          const { required, minAge, maxAge } = value
          sourceOptions.required = (required ? 1 : 0)
          if (sourceOptions.required) {
            sourceOptions.minAge = minAge ? Number(minAge) : ''
            sourceOptions.maxAge = maxAge ? Number(maxAge) : ''
          } else {
            sourceOptions.minAge = ''
            sourceOptions.maxAge = ''
          }
          // sourceOptions.whiteList = whiteList ? 1 : 0
        } else if (type === 'infoCheck') {
          sourceOptions.required = value || 0
        }
      }
      formData[sourceKey] = sourceModule
    }
  }
  return formData
}
