<template>
  <app-page>
    <page-header>
      产品管理
      <template #side>
        <el-button icon="el-icon-plus" @click="toAddProductType">新增产品类型</el-button>
      </template>
    </page-header>

    <el-table
      v-loading="loading"
      border
      :height="tableHeight"
      :data="rows"
    >
      <el-table-column
        v-for="item in fields"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.minWidth"
      />
      <el-table-column prop="status" label="状态">
        <template #="{ row }">
          <el-tag
            v-if="row.enable === -1"
            effect="dark"
            type="danger"
            size="small"
          >已停用</el-tag>
          <el-tag
            v-else-if="row.enable === 0"
            effect="dark"
            type="info"
            size="small"
          >待生效</el-tag>
          <el-tag
            v-else-if="row.enable === 1"
            effect="dark"
            type="success"
            size="small"
          >生效中</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" :min-width="100">
        <template #="{ row }">
          <el-popconfirm
            v-if="row.enable === 1"
            title="确定要禁用吗？"
            @confirm="changeStatus(row.id, false)"
          >
            <template #reference>
              <el-button type="text" size="mini">禁用</el-button>
            </template>
          </el-popconfirm>
          <el-button
            v-else
            type="text"
            size="mini"
            @click="changeStatus(row.id, true)"
          >启用</el-button>
          <el-popconfirm
            v-if="row.enable === 0 || (row.enable === -1 && row.productNum === 0)"
            title="确定要删除吗？"
            @confirm="deleteRow(row.id)"
          >
            <template #reference>
              <el-button type="text" size="mini">删除</el-button>
            </template>
          </el-popconfirm>
          <el-button
            type="text"
            size="mini"
            @click="$router.push({ name: 'accounts_products_product', query: { id: row.id } })"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <app-pagination /> -->
  </app-page>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { getProductList, setProductStatus, deleteProduct } from '@/api/product'
import type { Product } from '@/api/model/productModel'
import { ElMessage } from 'element-plus'

const fields = [
  { label: '编号', prop: 'code', minWidth: 50 },
  { label: '产品类型', prop: 'name' },
  { label: '产品说明', prop: 'remark' },
  { label: '产品数量', prop: 'productNum' }
]

export default defineComponent({
  name: 'Products',
  methods: {
    toAddProductType() {
      this.$router.push({ name: 'accounts_products_product' })
    }
  },
  setup() {
    const loading = ref<boolean>(false)

    // 列表加载
    // -----------------------------
    const rows = ref<Product[]>([])
    const loadList = async () => {
      loading.value = true
      const res = await getProductList()
      loading.value = false
      if (res.success) {
        rows.value = res.result || []
      }
    }
    loadList()

    // 状态更改
    // -----------------------------
    const changeStatus = async (id: number, isEnabled: boolean) => {
      const enable = isEnabled ? 1 : -1
      loading.value = true
      const res = await setProductStatus({ id, enable })
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      } else {
        loading.value = false
      }
    }

    // 删除
    // -----------------------------
    const deleteRow = async (id: number) => {
      loading.value = true
      const res = await deleteProduct({ id })
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      } else {
        loading.value = false
      }
    }
    return {
      loading,
      fields,
      rows,
      tableHeight: window.innerHeight - 200,
      changeStatus,
      deleteRow
    }
  }
})
</script>
