
import { defineComponent, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AccountSearchForm from './components/AccountSearchForm.vue'
import { getAccountList, getSettleDetail } from '@/api/servicer'
import type { AccountListParams } from '@/api/model/servicerModel'
import AddAccountDialog from './components/AddAccountDialog.vue'

// List
const fields = [
  { label: '账户账号', prop: 'accountNo' },
  { label: '服务团队', prop: 'teamName' },
  { label: '团队管理员', prop: 'teamLeader' },
  { label: '总剩余用量', prop: 'unuseNum' },
  { label: '总累计用量', prop: 'usedNum' },
  { label: '服务产品', prop: 'serviceName' },
  { label: '操作', prop: 'action' }
]

export default defineComponent({
  name: 'AccountList',
  components: { AccountSearchForm, AddAccountDialog },
  setup() {
    const loading = ref<boolean>(false)
    // 服务类型
    const store = useStore()
    const route = useRoute()
    const { sysServiceId } = route.query
    const serviceEnum = store.state.enumData.enumInfo.service
    // 服务列表
    // -------------------------------
    const params = reactive<AccountListParams>({
      teamId: null
    })
    const rows = ref()
    const pager = reactive({
      page: 1,
      total: 0,
      pageSize: 10
    })
    const loadList = async (
      page: number = pager.page,
      pageSize: number = pager.pageSize
    ) => {
      loading.value = true
      const res = await getAccountList({
        ...params,
        pageNo: page,
        pageSize: pageSize
      })
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result.data
        pager.total = res.result.total
        pager.page = page
        pager.pageSize = pageSize
      }
    }
    loadList()

    // 搜索
    const handleSearch = () => {
      loadList(1, pager.pageSize)
    }
    const handleSizeChange = (pageSize) => {
      loadList(1, pageSize)
    }
    const handleCurrentChange = (page: number) => {
      loadList(page)
    }

    // 新增账户
    // -------------------------------
    const accountDialog = ref()
    const openAccountDialog = () => {
      currentTeamId.value = ''
      accountDialog.value.open()
    }
    // 获取账号详情
    // -------------------------------
    const currentTeamId = ref()
    const currentLoadingId = ref(0)
    const currentAccountId = ref(0)
    const handleEditAccount = (id) => {
      console.log(id)
      currentLoadingId.value = id
      currentTeamId.value = ''
      currentAccountId.value = id
      getSettleDetail({ id }).then(res => {
        currentLoadingId.value = 0
        if (res.success && res.result) {
          currentTeamId.value = res.result.teamId
          accountDialog.value.open()
        }
      })
    }
    return {
      loading,
      params,
      fields,
      rows,
      handleSearch,
      accountDialog,
      pager,
      serviceEnum,
      sysServiceId,
      currentTeamId,
      currentLoadingId,
      currentAccountId,
      openAccountDialog,
      handleSizeChange,
      handleCurrentChange,
      handleEditAccount
    }
  }
})
