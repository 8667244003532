<template>
  <app-page>
    <search-bar>
      <account-search-form v-model="params" @submit="handleSearch" />
      <template #side>
        <el-button icon="el-icon-plus" @click="openAccountDialog">新增账户</el-button>
      </template>
    </search-bar>

    <div class="table-container">
      <fluid-table v-loading="loading" :fields="fields" :data="rows">
        <template #status="{ row }">
          <el-tag
            v-if="row.enable === 0"
            effect="dark"
            type="danger"
            size="small"
          >待生效</el-tag>
          <el-tag
            v-else
            effect="dark"
            type="success"
            size="small"
          >生效中</el-tag>
        </template>
        <template #action="{ row }">
          <a
            class="table-link-button"
            @click="changeStatus(row)"
          >{{ row.enable === 0 ? '启用' : '禁用' }}</a>
          <router-link
            class="table-link-button"
            :to="{ name: 'accounts_accounts_edit', query: { id: row.id } }"
          >编辑</router-link>
        </template>
      </fluid-table>
    </div>
  </app-page>
  <add-account-dialog ref="accountDialog" @success="handleSearch" />
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import AccountSearchForm from './components/AccountSearchForm.vue'
import AddAccountDialog from './components/AddAccountDialog.vue'
import { getAccountList, setAccountStatus } from '@/api/account'
import type { Account, AccountListParams as ApiAccountListParams } from '@/api/model/accountModel'
import type { AccountListParams } from './types'
import { dateFormat } from '@/utils/date'

// List
const fields = [
  { label: '编号', prop: 'id' },
  { label: '账户名称', prop: 'name' },
  { label: '类型', prop: 'type' },
  { label: '产品数量', prop: 'productNum' },
  { label: '服务客户数', prop: 'serviceNum' },
  { label: '服务人数', prop: 'serPerNum' },
  { label: '联系人', prop: 'leader' },
  { label: '联系电话', prop: 'mobile' },
  { label: '支付通道', prop: 'payChannel' },
  { label: '三方服务', prop: 'service', minWidth: 150, showOverflowTooltip: true },
  { label: '收费模式', prop: 'xxxxxxxx' }, // 暂无
  { label: '创建者', prop: 'leader' },
  { label: '创建时间', prop: 'createdTime' },
  { label: '状态', prop: 'status', minWidth: 80 },
  { label: '操作', prop: 'action', fixed: 'right', width: 80 }
]

function toFormData(params: AccountListParams): ApiAccountListParams {
  const data: ApiAccountListParams = {}
  const { name, type, status, created } = params
  if (name) {
    data.name = name
  }
  if (type) {
    data.type = Number(type)
  }
  if (status !== '') {
    data.status = Number(status)
  }
  if (created && created.length > 0) {
    data.startDate = dateFormat(created[0], 'YY-MM-dd')
    data.endDate = dateFormat(created[1], 'YY-MM-dd')
  }
  return data
}

export default defineComponent({
  name: 'Accounts',
  components: { AccountSearchForm, AddAccountDialog },
  setup() {
    const loading = ref<boolean>(false)

    // 账户列表
    // -------------------------------
    const params = reactive<AccountListParams>({
      name: '',
      type: 1,
      status: '',
      created: []
    })
    const rows = ref<Account[]>([])
    const loadList = async () => {
      loading.value = true
      const res = await getAccountList(toFormData(params))
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result
      }
    }
    loadList()

    // 搜索
    const handleSearch = loadList

    const formatCol = (row, col) => {
      if (col.property === 'createdTime') {
        return dateFormat(row.createdTime)
      }
      if (col.property === 'payChannel') {
        return '全部'
      }
      return row[col.property] || '--'
    }

    // 更改状态
    // -------------------------------
    const changeStatus = async (row: Account) => {
      loading.value = true
      const { id, enable } = row
      const res = await setAccountStatus({
        id,
        enable: enable === 0 ? 1 : 0
      })
      if (res.success) {
        ElMessage.success('操作成功')
        // 重新加载列表
        loadList()
      }
    }

    // 新增账户
    // -------------------------------
    const accountDialog = ref()
    const openAccountDialog = () => {
      accountDialog.value.open()
    }

    return {
      loading,
      params,
      fields,
      rows,
      // tableHeight: window.innerHeight - 200,
      formatCol,
      handleSearch,
      changeStatus,
      accountDialog,
      openAccountDialog
    }
  }
})
</script>
<style lang="less" scoped>
.table-container {
  min-height: calc(100vh - 1rem);
  margin-bottom: 20px;
}
</style>
