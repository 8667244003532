<template>
  <app-page>
    <div class="table-container">
      <div class="toolbar">
        <div class="back-button">
          <el-button @click="$router.back()">返回</el-button>
        </div>
        <div class="account-props">
          <div class="account-prop">服务团队：{{ $route.query.teamName }}</div>
          <div class="account-prop">总剩余用量：{{ $route.query.unuseNum }}</div>
          <div class="account-prop">总累计用量：{{ $route.query.usedNum }}</div>
        </div>
      </div>
      <fluid-table v-loading="loading" :fields="fields" :data="list">
        <template #authFileUrl="{ row }">
          <a :href="row.authFileUrl" target="_blank">点击查看</a>
        </template>
        <template #status="{ row }">
          <el-switch :value="row.status === 1" @change="handleStatusChange(row)" />
        </template>
      </fluid-table>
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { getAccountDetail, changeSettleStatus } from '@/api/servicer'
import type { ServicerUsedItem } from '@/api/model/servicerModel'
import { dateFormat } from '@/utils/date'

const fields = [
  { label: '发起方', prop: 'companyName' },
  { label: '合同管理员', prop: 'admin' },
  { label: '协议书', prop: 'authFileUrl', width: 120 },
  { label: '累计用量', prop: 'usedNum' },
  { label: '状态', prop: 'status' },
  { label: '创建时间', prop: 'createdAt', formatter: row => dateFormat(row.createdAt) }
]

export default defineComponent({
  name: 'AccountDetail',
  setup() {
    const route = useRoute()
    const id = Number(route.query.id)

    const loading = ref(false)
    const list = reactive<ServicerUsedItem[]>([])
    const loadList = async () => {
      loading.value = true
      const res = await getAccountDetail(id)
      loading.value = false
      if (res.success && res.result) {
        list.splice(0)
        list.push(...res.result)
      }
    }
    loadList()

    // 禁用/启用
    const handleStatusChange = row => changeStatus(row)
    const changeStatus = async (row) => {
      const status = row.status ? '0' : '1'
      const res = await changeSettleStatus({ id: row.id, status }, { loading: true })
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      }
    }

    return {
      loading,
      fields,
      list,
      handleStatusChange
    }
  }
})
</script>
<style lang="less" scoped>
.table-container {
  margin-bottom: 30px;

  .toolbar {
    position: relative;
    margin: 20px 0;

    .back-button {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.account-props {
  line-height: 32px;
  text-align: center;
}
.account-prop {
  display: inline-block;

  & + & {
    margin-left: 50px;
  }
}
</style>
