
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import PlaceholderSearchForm from './components/PlaceholderSearchForm.vue'
import { getPlaceholderList, movePlaceholder, delPlaceholder } from '@/api/servicer'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { dateFormat } from '@/utils/date'
import { categoryEnum, placeholderTypeEnum } from '@/config/enums/servicer'

export default defineComponent({
  name: 'PlaceholderList',
  components: { PlaceholderSearchForm },
  setup() {
    const loading = ref<boolean>(false)
    const route = useRoute()
    const router = useRouter()
    // 服务列表
    // -------------------------------
    interface placeholderSet {
      id?: string,
      name?: string
    }
    const params = reactive<placeholderSet>({
      id: route.query.id?.toString()
    })
    const rows = ref()
    const loadList = async (
    ) => {
      loading.value = true
      const res = await getPlaceholderList(params)
      loading.value = false
      if (res.success) {
        rows.value = res.result
      }
    }
    loadList()

    // 搜索
    const handleSearch = () => {
      loadList()
    }

    // 删除
    // -------------------------------
    const handleDel = async (row) => {
      loading.value = true
      const { id } = row
      const res = await delPlaceholder({
        id
      })
      if (res.success) {
        ElMessage.success('操作成功')
        // 重新加载列表
        loadList()
      }
    }
    const handleMove = async (row, offset) => {
      loading.value = true
      const { id } = row
      const res = await movePlaceholder({
        id,
        offset
      })
      if (res.success) {
        ElMessage.success('操作成功')
        // 重新加载列表
        loadList()
      }
    }
    const handleEdit = (row) => {
      sessionStorage.setItem('placeholder', JSON.stringify(row))
      router.push({ name: 'servicer_config_placeholderEdit', query: { aid: route.query.id, id: row.id } })
    }
    return {
      loading,
      params,
      rows,
      handleSearch,
      categoryEnum,
      placeholderTypeEnum,
      handleDel,
      handleMove,
      handleEdit
    }
  }
})
