/**
 * 账户管理
 */
import { get, post } from '@/utils/http/request'
import type { RequestOptions } from '@/utils/http/types'
import type { AccountListParams, Account, Account2, Account3 } from './model/accountModel'

// 新增账户团队
export function addAccount(
  data: Account2,
  options?: RequestOptions
) {
  return post('user/account', data, options)
}

// 编辑账户
export function updateAccount(
  data: Account2,
  options?: RequestOptions
) {
  return post('user/account/update', data, options)
}

// 详情
export function getAccountDetail(params: { id: number }, options?: RequestOptions) {
  return get<Account3>('user/account', params, options)
}

// 账户列表
export function getAccountList(data: AccountListParams, options?: RequestOptions) {
  return post<Account[]>('user/account/list', data, options)
}

// 更改账户状态
export function setAccountStatus(params: { id: number, enable: number }, options?: RequestOptions) {
  return get('user/account/enable', params, options)
}
