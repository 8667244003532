<template>
  <el-form-item
    v-for="item, index in fields"
    :key="index"
    :label="item"
  >
    <el-radio-group :model-value="true" :disabled="true">
      <el-radio :label="false">不需要</el-radio>
      <el-radio :label="true">需要</el-radio>
    </el-radio-group>
  </el-form-item>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

const fields = [
  '产品名称',
  '产品Logo',
  '产品说明',
  '申报模式',
  '供应商',
  '所在地区',
  '产品介绍图',
  '联系电话'
]

export default defineComponent({
  name: 'ProductModuleBasic',
  setup() {
    return {
      fields
    }
  }
})
</script>
