
import { defineComponent } from 'vue'

const fields = [
  '产品名称',
  '产品Logo',
  '产品说明',
  '申报模式',
  '供应商',
  '所在地区',
  '产品介绍图',
  '联系电话'
]

export default defineComponent({
  name: 'ProductModuleBasic',
  setup() {
    return {
      fields
    }
  }
})
