
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { savePlaceholder, addServicer, getServicerDetail } from '@/api/servicer'
import { useStore } from 'vuex'
import type { PlaceholderSet } from '@/api/model/servicerModel'
import { categoryEnum, placeholderTypeEnum } from '@/config/enums/servicer'

export default defineComponent({
  name: 'PlaceholderEdit',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const { id, aid } = route.query
    const formData = ref<PlaceholderSet>({
      id: id ? id.toString() : '',
      adminServiceSettingId: aid ? aid.toString() : '',
      name: '',
      placeholderType: '',
      sort: '',
      category: '',
      remark: ''
    })

    // 获取数据
    // ------------------------------------------------
    // 第三方服务
    const serviceProviders = computed(() => store.getters.enums.get('service/service') || new Map())
    // 详情
    const loadDetail = async () => {
      const _data = JSON.parse(sessionStorage.getItem('placeholder') || '')
      formData.value.name = _data.name
      formData.value.placeholderType = _data.placeholderType.toString()
      formData.value.sort = _data.sort
      formData.value.category = _data.category.toString()
      formData.value.remark = _data.remark
    }
    if (id) {
      loadDetail()
    }

    // 表单提交
    // ------------------------------------------------
    const submitting = ref(false)
    const mainForm = ref()

    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      loading.value = true
      const res = id ? await savePlaceholder(formData.value) : await savePlaceholder(formData.value)
      loading.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'servicer_config_placeholderList', query: { id: aid } })
      }
    }
    const handleBack = () => {
      router.go(-1)
    }
    return {
      id,
      loading,
      serviceProviders,
      submitting,
      mainForm,
      formData,
      handleSubmit,
      handleBack,
      categoryEnum,
      placeholderTypeEnum
    }
  }
})
