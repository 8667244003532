
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { updateServicer, addServicer, getServicerDetail } from '@/api/servicer'
import { useStore } from 'vuex'
import type { ServiceFormSet } from '@/api/model/servicerModel'
import PriceNumList from './components/PriceNumList.vue'

export default defineComponent({
  components: { PriceNumList },
  name: 'ServicerEdit',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const { id } = route.query
    const formData = ref<ServiceFormSet>({
      serviceName: '',
      serviceType: '',
      enableNum: 0,
      sysServicePriceDTOList: []
    })
    const getValidatePass = (list) => {
      const _list = list.find(item => {
        return item.num.toString().trim() === '' || item.price.toString().trim() === ''
      })
      return !_list || _list.length <= 0
    }
    const validatePriceNum = (rule: any, value: any, callback: any) => {
      if (value.length === 0 || !getValidatePass(value)) {
        callback(new Error('请输入正取的服务单价,不能为空'))
      } else {
        callback()
      }
    }
    const rules = reactive({
      sysServicePriceDTOList: [{ validator: validatePriceNum, trigger: 'blur' }]
    })
    // 服务类型
    const serviceEnum = store.state.enumData.enumInfo.service

    // 获取数据
    // ------------------------------------------------
    // 第三方服务
    const serviceProviders = computed(() => store.getters.enums.get('service/service') || new Map())
    // 详情
    const loadDetail = async (id) => {
      const res = await getServicerDetail({ id })
      if (res.success && res.result) {
        formData.value = res.result
      }
    }
    if (id) {
      loadDetail(id)
    }

    // 表单提交
    // ------------------------------------------------
    const submitting = ref(false)
    const mainForm = ref()

    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      loading.value = true
      const res = id ? await updateServicer(formData.value) : await addServicer(formData.value)
      loading.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'servicer_servicer_list' })
      }
    }
    const handleBack = () => {
      router.go(-1)
    }
    return {
      id,
      loading,
      serviceProviders,
      rules,
      submitting,
      mainForm,
      formData,
      serviceEnum,
      handleSubmit,
      handleBack
    }
  }
})
