<template>
  <app-page>
    <div class="form-container">
      <block-title>
        <div class="block-title_txt" style="width:100px;">
          {{$route.query.id && $route.query.id !== '' ? '编辑占位符' : '新增占位符'}}
        </div>
      </block-title>
      <el-form
        ref="mainForm"
        v-loading="loading"
        :model="formData"
        :rules="rules"
        label-width="100px"
        @submit.prevent="handleSubmit"
      >
        <el-form-item
          prop="name"
          label="名称"
          :rules="{ required: true, message: '请输入名称' }"
        >
          <el-input v-model="formData.name" placeholder="请输入名称" maxlength="6" show-word-limit />
        </el-form-item>
        <el-form-item
          prop="placeholderType"
          label="类型"
          :rules="{ required: true, message: '请输入类型' }"
        >
          <el-select
            v-model="formData.placeholderType"
            placeholder="请输入类型"
            style="width: 100%"
          >
            <el-option
              v-for="item in Object.keys(placeholderTypeEnum)"
              :key="item"
              :value="item"
              :label="placeholderTypeEnum[item]"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="category"
          label="分类"
          :rules="{ required: true, message: '请输入分类' }"
        >
          <el-select
            v-model="formData.category"
            placeholder="请输入分类"
            style="width: 100%"
          >
            <el-option
              v-for="item in Object.keys(categoryEnum)"
              :key="item"
              :value="item"
              :label="categoryEnum[item]"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input v-model="formData.remark" placeholder="请输入服务描述" type="textarea" rows="5" />
        </el-form-item>
        <el-form-item class="form-footer">
          <el-button
            type="primary"
            native-type="submit"
            :loading="submitting"
          >保存</el-button>
          <el-button
            @click="handleBack()"
          >返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { savePlaceholder, addServicer, getServicerDetail } from '@/api/servicer'
import { useStore } from 'vuex'
import type { PlaceholderSet } from '@/api/model/servicerModel'
import { categoryEnum, placeholderTypeEnum } from '@/config/enums/servicer'

export default defineComponent({
  name: 'PlaceholderEdit',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const { id, aid } = route.query
    const formData = ref<PlaceholderSet>({
      id: id ? id.toString() : '',
      adminServiceSettingId: aid ? aid.toString() : '',
      name: '',
      placeholderType: '',
      sort: '',
      category: '',
      remark: ''
    })

    // 获取数据
    // ------------------------------------------------
    // 第三方服务
    const serviceProviders = computed(() => store.getters.enums.get('service/service') || new Map())
    // 详情
    const loadDetail = async () => {
      const _data = JSON.parse(sessionStorage.getItem('placeholder') || '')
      formData.value.name = _data.name
      formData.value.placeholderType = _data.placeholderType.toString()
      formData.value.sort = _data.sort
      formData.value.category = _data.category.toString()
      formData.value.remark = _data.remark
    }
    if (id) {
      loadDetail()
    }

    // 表单提交
    // ------------------------------------------------
    const submitting = ref(false)
    const mainForm = ref()

    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      loading.value = true
      const res = id ? await savePlaceholder(formData.value) : await savePlaceholder(formData.value)
      loading.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'servicer_config_placeholderList', query: { id: aid } })
      }
    }
    const handleBack = () => {
      router.go(-1)
    }
    return {
      id,
      loading,
      serviceProviders,
      submitting,
      mainForm,
      formData,
      handleSubmit,
      handleBack,
      categoryEnum,
      placeholderTypeEnum
    }
  }
})
</script>
<style lang="less" scoped>
.form-footer{
  text-align: right;
}
.block-title_txt{
 text-align: right;
 padding-right:10px;
 box-sizing: border-box;
}
</style>
