/**
 * 默认的产品配置数据
 */
import type { Product } from '@/types/product'

const product: Product = {
  type: '',
  description: '',
  businessType: '',
  infoSetting: {
    disabled: false,
    options: {
      basicInfo: { type: 'required', value: false, setting: 0 }, // 基础信息
      idCard: { type: 'required', value: false, setting: 0 }, // 身份证图片
      degreeDiploma: { type: 'required', value: false, setting: 0 }, // 学位证书
      diploma: { type: 'required', value: false, setting: 0 }, // 毕业证书
      bankCard: { type: 'required', value: false, setting: 0 }, // 银行卡正反面
      leavingCertificate: { type: 'required', value: false, setting: 0 }, // 离职证明
      wageDetail: { type: 'required', value: false, setting: 0 } // 工资流水
    }
  },
  riskManagement: {
    disabled: false,
    options: {
      contractCheck: { type: 'required', value: false, setting: 0 }, // 合同校验
      orcCheck: { type: 'required', value: false, setting: 0 }, // OCR校验
      livingCheck: { type: 'required', value: false, setting: 0 }, // 活体校验
      ageLimit: { type: 'ageLimit', value: { required: false, minAge: '', maxAge: '' }, setting: 0 }, // 年龄限制
      infoCheck: { type: 'infoCheck', value: 0, setting: 0 }, // 信息校验
      orderTaking: { type: 'required', value: false, setting: 0 }, // 接单校验
      dispensingCheck: { type: 'required', value: false, setting: 0 } // 发放校验
    }
  },
  socialSecurity: {
    disabled: false,
    options: {
      location: { type: 'required', value: false, setting: 0 }, // 缴纳地
      base: { type: 'required', value: false, setting: 0 }, // 社保基数
      individualProportion: { type: 'required', value: false, setting: 0 }, // 个人缴纳比例
      employerProportion: { type: 'required', value: false, setting: 0 }, // 公司缴纳比例
      accumulationFundBase: { type: 'required', value: false, setting: 0 }, // 公积金基数
      accumulationFundIndividualProportion: { type: 'required', value: false, setting: 0 }, // 个人缴纳比例
      accumulationFundEmployerProportion: { type: 'required', value: false, setting: 0 } // 公司缴纳比例
    }
  },
  settlement: {
    disabled: false,
    options: {
      valueAddedTax: { type: 'required', value: false, setting: 0 }, // 增值税
      individualIncomeTax: { type: 'required', value: false, setting: 0 }, // 个人所得税
      serviceFee: { type: 'required', value: false, setting: 0 }, // 服务费
      otherFee: { type: 'required', value: false, setting: 0 }, // 其他费用
      method: { type: 'required', value: false, setting: 0 }, // 结算方式
      period: { type: 'required', value: false, setting: 0 }, // 结算周期
      template: { type: 'required', value: false, setting: 0 } // 结算模板
    }
  },
  other: {
    disabled: false,
    options: {
      annualCapitalLimit: { type: 'required', value: false, setting: 0 }, // 年流水限额
      annualTaxlLimit: { type: 'required', value: false, setting: 0 }, // 年纳税限额
      invoicingLimit: { type: 'required', value: false, setting: 0 }, // 开票额限制
      invoiceType: { type: 'required', value: false, setting: 0 }, // 发票类型
      distributionLimit: { type: 'required', value: false, setting: 0 }, // 发放限额
      taxLimit: { type: 'required', value: false, setting: 0 } // 纳税限额
    }
  }
}

export default product
