<template>
  <app-page>
    <div class="form-container">
      <block-title>
        <div class="block-title_txt" style="width:100px;">
          {{$route.query.id && $route.query.id !== '' ? '编辑服务' : '新增服务'}}
        </div>
      </block-title>
      <el-form
        ref="mainForm"
        v-loading="loading"
        :model="formData"
        :rules="rules"
        label-width="100px"
        @submit.prevent="handleSubmit"
      >
        <el-form-item
          prop="serviceName"
          label="服务名称"
          :rules="{ required: true, message: '请输入服务名称' }"
        >
          <el-input v-model="formData.serviceName" placeholder="请输入服务名称" show-word-limit maxlength="8"/>
        </el-form-item>
        <el-form-item
          prop="serviceType"
          label="服务类型"
          :rules="{ required: true, message: '请输入服务类型' }"
        >
          <el-select
            v-model="formData.serviceType"
            placeholder="请选择服务类型"
            style="width: 100%"
          >
            <el-option
              v-for="item in serviceEnum"
              :key="item.id"
              :value="item.dataKey"
              :label="item.dataValue"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="serviceSupplier"
          label="服务提供商"
          :rules="{ required: true, message: '请选择服务提供商' }"
        >
          <el-input v-model="formData.serviceSupplier" placeholder="请选择服务提供商" />
        </el-form-item>
        <el-form-item
          prop="serviceUrl"
          label="服务地址"
          :rules="{ required: true, message: '请输入服务地址' }"
        >
           <el-input v-model="formData.serviceUrl" placeholder="请输入服务地址" />
        </el-form-item>
        <el-form-item
          prop="accountNo"
          label="账户账号"
          :rules="{ required: true, message: '请输入账户账号' }"
        >
           <el-input v-model="formData.accountNo" placeholder="请输入账户账号" />
        </el-form-item>
        <el-form-item
          prop="accountPwd"
          label="账户密码"
          :rules="{ required: true, message: '请输入账户密码' }"
        >
           <el-input v-model="formData.accountPwd" placeholder="请输入账户密码" />
        </el-form-item>
        <el-form-item
          prop="content"
          label="服务配置"
        >
          <el-input v-model="formData.content" type="textarea" placeholder="请输入账户名称" />
        </el-form-item>
        <el-form-item
          prop="sysServicePriceDTOList"
          :required="true"
          label="服务单价">
          <price-num-list v-if="formData" v-model="formData.sysServicePriceDTOList" />
        </el-form-item>
        <el-form-item
          prop="enableNum"
          label="可用数量"
          :rules=" { type: 'number', message: '必须是数字' }"
        >
          <el-input v-model.number="formData.enableNum" placeholder="请输入可用数量" />
        </el-form-item>
        <el-form-item
          prop="remark"
          label="服务描述"
        >
          <el-input v-model="formData.remark" placeholder="请输入服务描述" type="textarea" />
        </el-form-item>
        <el-form-item class="form-footer" v-if="!formDisabled">
          <el-button
            type="primary"
            native-type="submit"
            :loading="submitting"
          >保存</el-button>
          <el-button
            @click="handleBack()"
          >返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { updateServicer, addServicer, getServicerDetail } from '@/api/servicer'
import { useStore } from 'vuex'
import type { ServiceFormSet } from '@/api/model/servicerModel'
import PriceNumList from './components/PriceNumList.vue'

export default defineComponent({
  components: { PriceNumList },
  name: 'ServicerEdit',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const { id } = route.query
    const formData = ref<ServiceFormSet>({
      serviceName: '',
      serviceType: '',
      enableNum: 0,
      sysServicePriceDTOList: []
    })
    const getValidatePass = (list) => {
      const _list = list.find(item => {
        return item.num.toString().trim() === '' || item.price.toString().trim() === ''
      })
      return !_list || _list.length <= 0
    }
    const validatePriceNum = (rule: any, value: any, callback: any) => {
      if (value.length === 0 || !getValidatePass(value)) {
        callback(new Error('请输入正取的服务单价,不能为空'))
      } else {
        callback()
      }
    }
    const rules = reactive({
      sysServicePriceDTOList: [{ validator: validatePriceNum, trigger: 'blur' }]
    })
    // 服务类型
    const serviceEnum = store.state.enumData.enumInfo.service

    // 获取数据
    // ------------------------------------------------
    // 第三方服务
    const serviceProviders = computed(() => store.getters.enums.get('service/service') || new Map())
    // 详情
    const loadDetail = async (id) => {
      const res = await getServicerDetail({ id })
      if (res.success && res.result) {
        formData.value = res.result
      }
    }
    if (id) {
      loadDetail(id)
    }

    // 表单提交
    // ------------------------------------------------
    const submitting = ref(false)
    const mainForm = ref()

    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      loading.value = true
      const res = id ? await updateServicer(formData.value) : await addServicer(formData.value)
      loading.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'servicer_servicer_list' })
      }
    }
    const handleBack = () => {
      router.go(-1)
    }
    return {
      id,
      loading,
      serviceProviders,
      rules,
      submitting,
      mainForm,
      formData,
      serviceEnum,
      handleSubmit,
      handleBack
    }
  }
})
</script>
<style lang="less" scoped>
.form-footer{
  text-align: right;
}
.block-title_txt{
 text-align: right;
 padding-right:10px;
 box-sizing: border-box;
}
</style>
