<template>
  <el-form-item :label="label" class="collapse-title">
    <div class="collapse-header" :class="{ 'is-expended': isExpended }">
      <a
        class="collapse-header__toggle-button"
        href="javascript:void(0)"
        @click="toggleExpended"
      >
        <i class="el-icon-caret-right"></i>
      </a>
      <el-switch
        v-if="enabled !== undefined"
        :model-value="enabled"
        class="collapse-header__enable-switch"
        @change="$emit('update:enabled', $event)"
      />
    </div>
  </el-form-item>
  <div class="collapse-content" v-if="enabled !== false" v-show="expended">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'

export default defineComponent({
  name: 'CollapsableItemsGroup',
  emits: ['update:extended', 'update:enabled', 'toggle-expend'],
  props: {
    label: String as PropType<string>,
    enabled: Boolean as PropType<boolean>,
    expended: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup(props, { emit }) {
    // 展开
    // ----------------------------------------------------------
    const toggleExpended = () => {
      emit('update:extended', !props.expended)
      emit('toggle-expend', !props.expended)
    }
    const isExpended = computed(() => props.enabled !== false && props.expended)

    return {
      isExpended,
      toggleExpended
    }
  }
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");
.collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__edit {
    color: @brand_primary;
  }

  &__toggle-button {
    font-size: 16px;
    color: @gray_dark;
  }
}

.is-expended {
  .collapse-header__toggle-button {
    i {
      transform: rotate(90deg);
    }
  }
}

.collapse-title {
  :deep(.el-form-item__label) {
    font-weight: bold;
  }
}
</style>
