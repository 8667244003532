<template>
  <el-form inline v-model="formData" @submit.prevent="handleSubmit">
    <el-form-item label="占位符名称" prop="name">
      <el-input
        clearable
        v-model.trim="formData.name"
        placeholder="请输入"
        style="width: 120px"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" native-type="submit" icon="el-icon-search">搜索</el-button>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { defineComponent, PropType, toRef } from 'vue'
import { customerTypes, customerStates } from '@/config/enums/account'
import { useStore } from 'vuex'
interface placeholderSet {
  id?: string,
  name?: string
}

export default defineComponent({
  name: 'ServicerSearchForm',
  emits: ['submit'],
  props: {
    modelValue: {
      type: Object as PropType<placeholderSet>,
      required: true
    }
  },
  setup(props, { emit }) {
    // 服务类型
    const store = useStore()
    const serviceEnum = store.state.enumData.enumInfo.service
    const formData = toRef(props, 'modelValue')

    const handleSubmit = () => {
      emit('submit', formData.value)
    }

    return {
      customerTypes,
      customerStates,
      formData,
      serviceEnum,
      handleSubmit
    }
  }
})
</script>
