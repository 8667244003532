
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getAccountDetail, updateAccount } from '@/api/account'
import type { Account2 } from '@/api/model/accountModel'
import { getServicerList } from '@/api/servicer'

type AppAccount = Omit<Account2, 'teamDingConfigDTO'> & Account2['teamDingConfigDTO']

export default defineComponent({
  name: 'AccountEdit',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const { id } = route.query
    const formData = reactive<AppAccount>({
      id: Number(id),
      userName: '',
      teamName: '',
      mobile: '',
      service: [],
      token: '',
      aesKey: '',
      suiteKey: '',
      appKey: '',
      appSecret: '',
      processCode: ''
    })

    const accountType = 0

    // 获取数据
    // ------------------------------------------------

    // 第三方服务
    const serviceProviders = computed(() => store.getters.enums.get('service/service') || new Map())
    // 详情
    const loadDetail = async () => {
      const res = await getAccountDetail({ id: Number(id) })
      if (res.success && res.result) {
        const detail = res.result
        if (!detail.teamDingConfigDTO) {
          detail.teamDingConfigDTO = {
            teamId: 0,
            token: '',
            aesKey: '',
            suiteKey: '',
            appKey: '',
            appSecret: '',
            processCode: ''
          }
        }
        formData.teamName = detail.teamName
        formData.userName = detail.teamLeader
        formData.mobile = detail.teamLeaderMobile
        formData.service = detail.service
        formData.payChannels = []
        formData.token = detail.teamDingConfigDTO.token
        formData.aesKey = detail.teamDingConfigDTO.aesKey
        formData.suiteKey = detail.teamDingConfigDTO.suiteKey
        formData.appKey = detail.teamDingConfigDTO.appKey
        formData.appSecret = detail.teamDingConfigDTO.appSecret
        formData.processCode = detail.teamDingConfigDTO.processCode
      }
    }
    // 获取可用的服务商
    const serverProductList = ref()
    const getServicerData = async() => {
      const res = await getServicerList({
        status: '1',
        pageNo: 1,
        pageSize: 99999
      })
      if (res.success && res.result) {
        serverProductList.value = res.result.data
      }
    }
    const loadData = () => {
      loading.value = true
      Promise.all([
        getServicerData(),
        loadDetail()
      ]).then(() => {
        loading.value = false
      })
    }
    loadData()

    // 表单提交
    // ------------------------------------------------
    const submitting = ref(false)
    const mainForm = ref()

    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      loading.value = true
      const {
        id,
        userName,
        teamName,
        mobile,
        service,
        token,
        aesKey,
        suiteKey,
        appKey,
        appSecret,
        processCode
      } = formData
      const data: Account2 = {
        id,
        userName,
        teamName,
        mobile,
        service,
        teamDingConfigDTO: null
      }
      if (token || aesKey || suiteKey || appKey || appSecret || processCode) {
        data.teamDingConfigDTO = {
          token,
          aesKey,
          suiteKey,
          appKey,
          appSecret,
          processCode
        }
      }
      const res = await updateAccount(data)
      loading.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'accounts_accounts_accounts' })
      }
    }

    return {
      id,
      loading,
      serviceProviders,
      accountType,
      submitting,
      mainForm,
      formData,
      serverProductList,
      handleSubmit
    }
  }
})
