/**
 * 产品管理
 */
import { get, post, request } from '@/utils/http/request'
import type { RequestOptions } from '@/utils/http/types'
import type { Product } from './model/productModel'

export function addProduct(data, options?: RequestOptions) {
  return post('productKind', data, options)
}
export function updateProduct(data, options?: RequestOptions) {
  return post('productKind/update', data, options)
}

export function getProductList() {
  return get<Product[]>('productKind/list')
}

export function setProductStatus(
  params: { id: number, enable: number },
  options?: RequestOptions
) {
  return get('productKind/enable', params, options)
}

export function deleteProduct(params: { id: number }, options?: RequestOptions) {
  return request({
    url: 'productKind/del',
    method: 'delete',
    params
  }, options)
}

export function getProductDetail(
  params: { id: number },
  options?: RequestOptions
) {
  return get<Product>('productKind', params, options)
  // return get<ProductSetting>('productKind/' + params.id, {}, options)
}
