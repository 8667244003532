<template>
  <app-page>
    <search-bar>
      <!-- <config-search-form v-model="params" @submit="handleSearch" /> -->
      <!-- <template #side>
        <el-button icon="el-icon-plus" @click="$router.push({ name: 'servicer_servicer_edit' })">新增服务</el-button>
      </template> -->
    </search-bar>
    <div style="min-height: calc(100vh - 1rem)">
      <el-table
        v-loading="loading"
        class="regular-table mb2"
        border
        :data="rows"
      >
        <el-table-column
          v-for="item in fields"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
          show-overflow-tooltip
          :formatter="formatCol"
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="160px">
          <template #="{ row }">
            <el-button
              type="text"
              @click="$router.push({ name: 'servicer_config_placeholderList', query: { id: row.id }})"
            >查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import ConfigSearchForm from './components/ConfigSearchForm.vue'
import { getPlaceSettingList, changeServicerStatus } from '@/api/servicer'
import { useStore } from 'vuex'
import { dateFormat } from '@/utils/date'

// List
const fields = [
  { label: '编号', prop: 'id', minWidth: 50 },
  { label: '配置名称', prop: 'name' },
  { label: '适用范围', prop: 'applyTo', minWidth: 100 },
  { label: '更新时间', prop: 'updatedAt', minWidth: 100 },
  { label: '备注说明', prop: 'remark', minWidth: 80 }
]

export default defineComponent({
  name: 'ConfigList',
  // components: { ConfigSearchForm },
  setup() {
    const loading = ref<boolean>(false)
    // 服务类型
    const store = useStore()
    const serviceEnum = store.state.enumData.enumInfo.service
    // 服务列表
    // -------------------------------
    interface configSet {
      keyword?: string
    }
    const params = reactive<configSet>({})
    const rows = ref()
    const loadList = async () => {
      loading.value = true
      const res = await getPlaceSettingList(params)
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result
      }
    }
    loadList()

    // 搜索
    const handleSearch = () => {
      loadList()
    }

    const formatCol = (row, col) => {
      if (col.property === 'created_at') {
        return dateFormat(row.updatedAt)
      }
      return row[col.property]
    }
    return {
      loading,
      params,
      fields,
      rows,
      formatCol,
      handleSearch,
      serviceEnum
    }
  }
})
</script>
