<template>
  <div class="tag-container">
    <div class="input-box" :key="index" v-for="(item, index) in formData">
      <el-input
        v-model="item.price"
        class="input-new"
        size="small">
        <template #append>元</template>
      </el-input>
      <span>/</span>
      <el-input
        v-model="item.num"
        class="input-new"
        size="small">
        <template #append>条</template>
      </el-input>
      <el-button size="small" @click="handleRemove(index)">删除</el-button>
    </div>
    <el-button v-if="!formData || formData.length < 5" class="button-new" size="small" @click="handleAdd">新增</el-button>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  computed,
  watch,
  reactive
} from 'vue'

export default defineComponent({
  name: 'PriceNumList',
  components: { },
  props: [
    'modelValue'
  ],
  setup(props, { emit }) {
    type PriceNumSet = {
      num?: string,
      price?: string
    }
    const formData = computed(() => (
      props.modelValue
    ))
    // 新增标签的事件
    const handleAdd = () => {
      formData.value.push({
        num: '',
        price: ''
      })
    }
    const handleRemove = (index) => {
      formData.value.splice(index, 1)
    }
    watch(() => [formData], () => {
      emit('update:modelValue', formData)
    },
    {
      deep: true // 深度监听的参数
    }
    )
    return {
      formData,
      handleAdd,
      handleRemove
    }
  }
})
</script>
<style scoped lang="less">
  .tag-container{
    display: flex;
    flex-wrap: wrap;
    vertical-align: bottom;
    align-items: center;
  }
.input-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 10px;
  span{
    margin: 0 10px 0 0;
  }
}
.input-new {
    // width: 200px;
    margin-right: 10px;
    vertical-align: bottom;
    margin-bottom: 10px;
}
// .button-new{
// }
</style>
