
import {
  defineComponent,
  computed,
  watch,
  reactive
} from 'vue'

export default defineComponent({
  name: 'PriceNumList',
  components: { },
  props: [
    'modelValue'
  ],
  setup(props, { emit }) {
    type PriceNumSet = {
      num?: string,
      price?: string
    }
    const formData = computed(() => (
      props.modelValue
    ))
    // 新增标签的事件
    const handleAdd = () => {
      formData.value.push({
        num: '',
        price: ''
      })
    }
    const handleRemove = (index) => {
      formData.value.splice(index, 1)
    }
    watch(() => [formData], () => {
      emit('update:modelValue', formData)
    },
    {
      deep: true // 深度监听的参数
    }
    )
    return {
      formData,
      handleAdd,
      handleRemove
    }
  }
})
