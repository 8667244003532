<template>
  <app-page>
    <search-bar>
      <placeholder-search-form v-model="params" @submit="handleSearch" />
      <template #side>
        <el-button icon="el-icon-plus" @click="$router.push({ name: 'servicer_config_placeholderEdit', query: { aid: $route.query.id } })">新增占位符</el-button>
      </template>
    </search-bar>
    <div style="min-height: calc(100vh - 1rem)">
      <table class="p-table" cellpadding="0" border="0" cellspacing="0">
        <thead>
          <tr>
            <th>编号</th>
            <th>占位符名称</th>
            <th>类型</th>
            <th>备注说明</th>
            <th>操作</th>
          </tr>
        </thead>
        <template :key="index" v-for="(item, index) in rows ? Object.keys(rows) : {}">
          <tr>
            <td colspan="5">{{categoryEnum[item]}}</td>
          </tr>
          <tr :key="it" v-for="(it, indexInner) in rows[item]">
            <td>
              {{it.id}}
            </td>
            <td>
              {{it.name}}
            </td>
            <td>
              {{placeholderTypeEnum[it.placeholderType]}}
            </td>
            <td>
              {{it.remark}}
            </td>
            <td class="option">
              <el-button
              type="text"
              @click="handleEdit(it)"
              >编辑</el-button>
              <el-button
                v-if="indexInner"
                type="text"
                @click="handleMove(it, -1)"
              >上移</el-button>
              <el-button
                v-if="indexInner !== rows[item].length - 1"
                type="text"
                @click="handleMove(it, 1)"
              >下移</el-button>
              <el-button
                type="text"
                @click="handleDel(it)"
              >删除</el-button>
            </td>
          </tr>
          </template>
    </table>
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import PlaceholderSearchForm from './components/PlaceholderSearchForm.vue'
import { getPlaceholderList, movePlaceholder, delPlaceholder } from '@/api/servicer'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { dateFormat } from '@/utils/date'
import { categoryEnum, placeholderTypeEnum } from '@/config/enums/servicer'

export default defineComponent({
  name: 'PlaceholderList',
  components: { PlaceholderSearchForm },
  setup() {
    const loading = ref<boolean>(false)
    const route = useRoute()
    const router = useRouter()
    // 服务列表
    // -------------------------------
    interface placeholderSet {
      id?: string,
      name?: string
    }
    const params = reactive<placeholderSet>({
      id: route.query.id?.toString()
    })
    const rows = ref()
    const loadList = async (
    ) => {
      loading.value = true
      const res = await getPlaceholderList(params)
      loading.value = false
      if (res.success) {
        rows.value = res.result
      }
    }
    loadList()

    // 搜索
    const handleSearch = () => {
      loadList()
    }

    // 删除
    // -------------------------------
    const handleDel = async (row) => {
      loading.value = true
      const { id } = row
      const res = await delPlaceholder({
        id
      })
      if (res.success) {
        ElMessage.success('操作成功')
        // 重新加载列表
        loadList()
      }
    }
    const handleMove = async (row, offset) => {
      loading.value = true
      const { id } = row
      const res = await movePlaceholder({
        id,
        offset
      })
      if (res.success) {
        ElMessage.success('操作成功')
        // 重新加载列表
        loadList()
      }
    }
    const handleEdit = (row) => {
      sessionStorage.setItem('placeholder', JSON.stringify(row))
      router.push({ name: 'servicer_config_placeholderEdit', query: { aid: route.query.id, id: row.id } })
    }
    return {
      loading,
      params,
      rows,
      handleSearch,
      categoryEnum,
      placeholderTypeEnum,
      handleDel,
      handleMove,
      handleEdit
    }
  }
})
</script>
<style lang="less" scoped>
.p-table{
  border-collapse:collapse;
  border:none;
  font-size: 12px;
  width: 100%;
  color: #606266;
  margin-bottom: 40px;
  th,td{
    border:solid #D8E1E8 1px;
    line-height: 36px;
    padding-left: 8px;
  }
  th{
    text-align: left;
    color: #303133;
    font-weight: bold;
    background-color: #F6F7F9;
  }
  .option{
    width: 200px;
  }
}
</style>
