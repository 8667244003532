<template>
  <app-page>
    <div class="form-container">
      <block-title>账户编辑</block-title>
      <el-form
        ref="mainForm"
        v-loading="loading"
        :model="formData"
        label-width="8em"
        @submit.prevent="handleSubmit"
      >
        <el-form-item
          prop="teamName"
          label="账户名称"
          :rules="{ required: true, message: '请输入账户名称' }"
        >
          <el-input v-model="formData.teamName" placeholder="请输入账户名称" />
        </el-form-item>
        <el-form-item
          prop="userName"
          label="联系人姓名"
          :rules="{ required: true, message: '请输入联系人姓名' }"
        >
          <el-input v-model="formData.userName" placeholder="请输入联系人姓名" />
        </el-form-item>
        <el-form-item
          prop="mobile"
          label="联系人电话"
          :rules="{ required: true, message: '请输入联系人电话' }"
        >
          <el-input v-model="formData.mobile" placeholder="请输入联系人电话" />
        </el-form-item>
        <el-form-item
          prop="service"
          label="第三方服务"
        >
          <el-select
            v-model="formData.service"
            multiple
            placeholder="请选择可使用的第三方服务（可多选）"
            style="width: 100%"
          >
            <el-option
              v-for="item in serverProductList"
              :label="item.service_name"
              :key="item.id"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="token" label="钉钉Token">
          <el-input v-model="formData.token" placeholder="请输入Token" />
        </el-form-item>
        <el-form-item prop="aesKey" label="钉钉aesKey">
          <el-input v-model="formData.aesKey" placeholder="请输入aesKey" />
        </el-form-item>
        <el-form-item prop="suiteKey" label="钉钉suiteKey">
          <el-input v-model="formData.suiteKey" placeholder="请输入suiteKey" />
        </el-form-item>
        <el-form-item prop="appKey" label="钉钉appKey">
          <el-input v-model="formData.appKey" placeholder="请输入appKey" />
        </el-form-item>
        <el-form-item prop="appSecret" label="钉钉appSecret">
          <el-input v-model="formData.appSecret" placeholder="请输入appSecret" />
        </el-form-item>
        <el-form-item prop="processCode" label="钉钉流程模板id">
          <el-input v-model="formData.processCode" placeholder="请输入流程模板id" />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            native-type="submit"
            :disabled="id == null"
            :loading="submitting"
          >保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getAccountDetail, updateAccount } from '@/api/account'
import type { Account2 } from '@/api/model/accountModel'
import { getServicerList } from '@/api/servicer'

type AppAccount = Omit<Account2, 'teamDingConfigDTO'> & Account2['teamDingConfigDTO']

export default defineComponent({
  name: 'AccountEdit',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const { id } = route.query
    const formData = reactive<AppAccount>({
      id: Number(id),
      userName: '',
      teamName: '',
      mobile: '',
      service: [],
      token: '',
      aesKey: '',
      suiteKey: '',
      appKey: '',
      appSecret: '',
      processCode: ''
    })

    const accountType = 0

    // 获取数据
    // ------------------------------------------------

    // 第三方服务
    const serviceProviders = computed(() => store.getters.enums.get('service/service') || new Map())
    // 详情
    const loadDetail = async () => {
      const res = await getAccountDetail({ id: Number(id) })
      if (res.success && res.result) {
        const detail = res.result
        if (!detail.teamDingConfigDTO) {
          detail.teamDingConfigDTO = {
            teamId: 0,
            token: '',
            aesKey: '',
            suiteKey: '',
            appKey: '',
            appSecret: '',
            processCode: ''
          }
        }
        formData.teamName = detail.teamName
        formData.userName = detail.teamLeader
        formData.mobile = detail.teamLeaderMobile
        formData.service = detail.service
        formData.payChannels = []
        formData.token = detail.teamDingConfigDTO.token
        formData.aesKey = detail.teamDingConfigDTO.aesKey
        formData.suiteKey = detail.teamDingConfigDTO.suiteKey
        formData.appKey = detail.teamDingConfigDTO.appKey
        formData.appSecret = detail.teamDingConfigDTO.appSecret
        formData.processCode = detail.teamDingConfigDTO.processCode
      }
    }
    // 获取可用的服务商
    const serverProductList = ref()
    const getServicerData = async() => {
      const res = await getServicerList({
        status: '1',
        pageNo: 1,
        pageSize: 99999
      })
      if (res.success && res.result) {
        serverProductList.value = res.result.data
      }
    }
    const loadData = () => {
      loading.value = true
      Promise.all([
        getServicerData(),
        loadDetail()
      ]).then(() => {
        loading.value = false
      })
    }
    loadData()

    // 表单提交
    // ------------------------------------------------
    const submitting = ref(false)
    const mainForm = ref()

    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }
    const submit = async () => {
      loading.value = true
      const {
        id,
        userName,
        teamName,
        mobile,
        service,
        token,
        aesKey,
        suiteKey,
        appKey,
        appSecret,
        processCode
      } = formData
      const data: Account2 = {
        id,
        userName,
        teamName,
        mobile,
        service,
        teamDingConfigDTO: null
      }
      if (token || aesKey || suiteKey || appKey || appSecret || processCode) {
        data.teamDingConfigDTO = {
          token,
          aesKey,
          suiteKey,
          appKey,
          appSecret,
          processCode
        }
      }
      const res = await updateAccount(data)
      loading.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'accounts_accounts_accounts' })
      }
    }

    return {
      id,
      loading,
      serviceProviders,
      accountType,
      submitting,
      mainForm,
      formData,
      serverProductList,
      handleSubmit
    }
  }
})
</script>
