
import { defineComponent, PropType, toRef } from 'vue'
import { customerTypes, customerStates } from '@/config/enums/account'
import { useStore } from 'vuex'
interface placeholderSet {
  id?: string,
  name?: string
}

export default defineComponent({
  name: 'ServicerSearchForm',
  emits: ['submit'],
  props: {
    modelValue: {
      type: Object as PropType<placeholderSet>,
      required: true
    }
  },
  setup(props, { emit }) {
    // 服务类型
    const store = useStore()
    const serviceEnum = store.state.enumData.enumInfo.service
    const formData = toRef(props, 'modelValue')

    const handleSubmit = () => {
      emit('submit', formData.value)
    }

    return {
      customerTypes,
      customerStates,
      formData,
      serviceEnum,
      handleSubmit
    }
  }
})
