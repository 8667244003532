
import { defineComponent, ref } from 'vue'
import { getProductList, setProductStatus, deleteProduct } from '@/api/product'
import type { Product } from '@/api/model/productModel'
import { ElMessage } from 'element-plus'

const fields = [
  { label: '编号', prop: 'code', minWidth: 50 },
  { label: '产品类型', prop: 'name' },
  { label: '产品说明', prop: 'remark' },
  { label: '产品数量', prop: 'productNum' }
]

export default defineComponent({
  name: 'Products',
  methods: {
    toAddProductType() {
      this.$router.push({ name: 'accounts_products_product' })
    }
  },
  setup() {
    const loading = ref<boolean>(false)

    // 列表加载
    // -----------------------------
    const rows = ref<Product[]>([])
    const loadList = async () => {
      loading.value = true
      const res = await getProductList()
      loading.value = false
      if (res.success) {
        rows.value = res.result || []
      }
    }
    loadList()

    // 状态更改
    // -----------------------------
    const changeStatus = async (id: number, isEnabled: boolean) => {
      const enable = isEnabled ? 1 : -1
      loading.value = true
      const res = await setProductStatus({ id, enable })
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      } else {
        loading.value = false
      }
    }

    // 删除
    // -----------------------------
    const deleteRow = async (id: number) => {
      loading.value = true
      const res = await deleteProduct({ id })
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      } else {
        loading.value = false
      }
    }
    return {
      loading,
      fields,
      rows,
      tableHeight: window.innerHeight - 200,
      changeStatus,
      deleteRow
    }
  }
})
