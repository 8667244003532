<template>
  <el-form inline v-model="formData" @submit.prevent="handleSubmit">
    <el-form-item label="服务团队" prop="teamId">
      <el-select
        v-model="formData.teamId"
        placeholder="全部"
        filterable
        clearable
      >
        <el-option
          v-for="item in services"
          :key="item.id"
          :value="item.id"
          :label="item.teamName"
        />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" native-type="submit" icon="el-icon-search">搜索</el-button>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { defineComponent, PropType, reactive, toRef } from 'vue'
import type { AccountListParams } from '@/api/model/servicerModel'
import { getServicerTeam } from '@/api/servicer'

export default defineComponent({
  name: 'AccountSearchForm',
  emits: ['submit'],
  props: {
    modelValue: {
      type: Object as PropType<AccountListParams>,
      required: true
    }
  },
  setup(props, { emit }) {
    // 服务团队列表
    const services = reactive<any[]>([])
    const loadServices = async () => {
      const res = await getServicerTeam()
      if (res.success && res.result) {
        services.splice(0)
        services.push(...res.result as any[])
      }
    }
    loadServices()

    const formData = toRef(props, 'modelValue')

    const handleSubmit = () => {
      emit('submit', formData.value)
    }

    return {
      services,
      formData,
      handleSubmit
    }
  }
})
</script>
