
import { defineComponent, PropType, toRef } from 'vue'
import { customerTypes, customerStates } from '@/config/enums/account'
// import { dateFormat } from '@/utils/date'
import type { AccountListParams } from '../types'
// import type { AccountListParams as ApiAccountListParams } from '@/api/model/accountModel'

export default defineComponent({
  name: 'AccountSearchForm',
  emits: ['submit'],
  props: {
    modelValue: {
      type: Object as PropType<AccountListParams>,
      required: true
    }
  },
  setup(props, { emit }) {
    // const formData = shallowReactive({
    //   name: '',
    //   type: 1,
    //   status: '',
    //   created: []
    // })
    const formData = toRef(props, 'modelValue')

    const handleSubmit = () => {
      // const data: ApiAccountListParams = {}
      // const { name, type, status, created } = formData
      // if (name) {
      //   data.name = name
      // }
      // if (type) {
      //   data.type = Number(type)
      // }
      // if (status !== '') {
      //   data.status = Number(status)
      // }
      // if (created && created.length > 0) {
      //   const [startDate, endDate] = (created as Date[]).map(date => dateFormat(date, 'YY-MM-dd'))
      //   data.startDate = startDate
      //   data.endDate = endDate
      // }
      emit('submit', formData.value)
    }

    return {
      customerTypes,
      customerStates,
      formData,
      handleSubmit
    }
  }
})
