<template>
  <el-form-item
    v-for="item in labels"
    :key="item.key"
    :ref="el => (formItems[item.key] = el)"
    :prop="`${moduleKey}.options.${item.key}.value`"
    :label="item.label"
    :rules="
      formData[item.key].type == 'ageLimit'
        ? [{ validator: ageLimitValidator }]
        : null
    "
  >
    <div class="space-between-center" style="min-height: 100%">
      <el-radio-group
        v-if="formData[item.key].type === 'required'"
        v-model="formData[item.key].value"
        :disabled="formData[item.key].disabled"
      >
        <el-radio :label="false">不需要</el-radio>
        <el-radio :label="true">需要</el-radio>
      </el-radio-group>

      <div class="flex-vertical-center" v-else-if="formData[item.key].type === 'ageLimit'">
        <el-radio-group
          :model-value="formData[item.key].value.required"
          :disabled="formData[item.key].disabled"
          @update:modelValue="formData[item.key].value.required = $event"
        >
          <el-radio :label="false">无限制</el-radio>
          <el-radio :label="true">有限制</el-radio>
        </el-radio-group>
        <range-input
          class="ml2"
          :model-value="[formData[item.key].value.minAge, formData[item.key].value.maxAge]"
          :disabled="formData[item.key].disabled"
          @update:modelValue="updateAgeLimitRange(formData[item.key], $event)"
        />
        <!-- <el-checkbox label="city" v-model="formData[item.key].value.whiteList">白名单用户可以发放</el-checkbox> -->
      </div>

      <!-- 风控配置 > 信息校验 -->
      <div class="flex-vertical-center" v-else-if="formData[item.key].type === 'infoCheck'">
        <el-radio-group
          v-model="formData[item.key].value"
          :disabled="formData[item.key].disabled"
        >
          <el-radio :label="0">不限制</el-radio>
          <el-radio :label="1">三要素</el-radio>
          <el-radio :label="2">四要素</el-radio>
        </el-radio-group>
      </div>

      <el-select
        v-model="formData[item.key].setting"
        placeholder="配置"
        style="width: 110px"
      >
        <el-option
          v-for="item, index in settingSelectOptions"
          :key="index"
          :value="index"
          :label="item"
        />
      </el-select>
    </div>
  </el-form-item>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref, toRef } from 'vue'
import type {
  ProductSettingInfo,
  ProductSettingRiskManagement,
  ProductSettingSocialSecurity,
  ProductSettingSettlement,
  ProductSettingOther
} from '@/types/product'
import RangeInput from '@/components/RangeInput.vue'

type ProductSettingModule = (
  ProductSettingInfo |
  ProductSettingRiskManagement |
  ProductSettingSocialSecurity |
  ProductSettingSettlement |
  ProductSettingOther
)

const moduleItemLabels = {
  basicSetting: {
    name: '产品名称',
    logo: '产品logo',
    description: '产品说明',
    declarationType: '申报模式',
    object: '服务主体',
    location: '所在地区',
    introduction: '产品介绍',
    tel: '联系电话'
  },
  infoSetting: {
    basicInfo: '基础信息',
    idCard: '身份证图片',
    degreeDiploma: '学位证书',
    diploma: '毕业证书',
    bankCard: '银行卡正反面',
    leavingCertificate: '离职证明',
    wageDetail: '工资流水'
  },
  riskManagement: {
    contractCheck: '合同校验',
    orcCheck: '身份证正反面',
    livingCheck: '活体校验',
    ageLimit: '年龄限制',
    infoCheck: '信息校验',
    orderTaking: '接单校验',
    dispensingCheck: '发放校验'
  },
  socialSecurity: {
    location: '缴纳地',
    base: '社保基数',
    individualProportion: '个人缴纳比例',
    employerProportion: '公司缴纳比例',
    accumulationFundBase: '公积金基数',
    accumulationFundIndividualProportion: '个人缴纳比例',
    accumulationFundEmployerProportion: '公司缴纳比例'
  },
  settlement: {
    valueAddedTax: '增值税',
    individualIncomeTax: '个人所得税',
    serviceFee: '服务费',
    otherFee: '其他费用',
    method: '结算方式',
    period: '结算周期',
    template: '结算模板'
  },
  other: {
    annualCapitalLimit: '年流水限额',
    annualTaxlLimit: '年纳税限额',
    invoicingLimit: '开票额限制',
    invoiceType: '发票类型',
    distributionLimit: '发放限额',
    taxLimit: '纳税限额'
  }
}

const settingSelectOptions = ['自定义', '系统配置', '隐藏']

const ageLimitValidator = (rule, value, callback) => {
  const { required, minAge, maxAge } = value
  if (required) {
    if (!minAge) {
      callback(new Error('请输入最小年龄'))
    } else if (!maxAge) {
      callback(new Error('请输入最大年龄'))
    } else {
      const min = Number(minAge)
      if (min < 0) {
        callback(new Error('年龄不能小于0'))
      } else {
        const max = Number(maxAge)
        if (max < min) {
          callback(new Error('最大年龄必须大于最小年龄'))
        }
      }
    }
  }
  callback()
}

export default defineComponent({
  name: 'ProductModule',
  components: { RangeInput },
  props: {
    model: {
      type: Object as PropType<ProductSettingModule>,
      required: true
    },
    moduleKey: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props) {
    const formItems = ref({})

    const formData = toRef(props, 'model')
    const labelMap = moduleItemLabels[props.moduleKey]
    const labels = computed(() => (
      Object.keys(labelMap)
        .filter(key => key in formData.value)
        .map(key => ({ key, label: labelMap[key] }))
    ))

    // 更新风控配置>年龄限制>年龄范围
    const updateAgeLimitRange = (row, ageRange) => {
      row.value.minAge = ageRange[0]
      row.value.maxAge = ageRange[1]
    }

    return {
      formItems,
      labels,
      formData,
      ageLimitValidator,
      updateAgeLimitRange,
      settingSelectOptions
    }
  }
})
</script>
