
import { defineComponent, ref, computed, toRaw } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import type { Product } from '@/types/product'
import defaultProduct from './defaultProduct'
import CollapsableItemsGroup from './components/CollapsableItemsGroup.vue'
import ProductModule from './components/ProductModule.vue'
import ProductModuleBasic from './components/ProductModuleBasic.vue'
import { toProductFormData, formatProduct } from './utils'
import { addProduct, updateProduct, getProductDetail } from '@/api/product'
import { useStore } from 'vuex'

function createDefaultProduct(): Product {
  return JSON.parse(JSON.stringify(defaultProduct))
}

const labelMap = {
  basicSetting: '基础配置',
  infoSetting: '信息配置',
  riskManagement: '风控配置',
  // socialSecurity: '社保公积金',
  // settlement: '结算配置',
  // account: '账户配置',
  other: '其他配置'
}

export default defineComponent({
  name: 'ProductForm',
  components: {
    CollapsableItemsGroup,
    ProductModule,
    ProductModuleBasic
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const submitting = ref(false)
    const proBusinessTypeEnum = store.state.enumData.enumInfo.pro_business_type
    // 表单
    // --------------------------------------------------------------
    const mainForm = ref()
    const formData = ref<Product>(createDefaultProduct())

    const modules = computed(() => (
      Object.keys(labelMap)
        .filter(
          key =>
            key === 'basicSetting' ||
            key === 'account' ||
            (key in formData.value && !formData.value[key].hidden)
        )
        .map(key => ({ key, label: labelMap[key] }))
    ))

    const handleSubmit = () => {
      // 校验提交
      mainForm.value.validate((valid, cols) => {
        if (valid) {
          submit(toProductFormData(toRaw(formData.value)))
        } else {
          const invalidCols = Object.keys(cols).map(col => col.split('.')[0])
          const extended = invalidCols.findIndex(field => field === currentModuleKey.value)
          if (extended < 0) {
            currentModuleKey.value = invalidCols.find(c => !!labelMap[c]) || null
          }
          ElMessage.error('表单未填写完整')
        }
      })
    }
    const submit = async (data) => {
      submitting.value = true
      const func = data.id ? updateProduct : addProduct
      const res = await func(data)
      submitting.value = false
      if (res.success) {
        ElMessage.success('保存成功')
        router.push({ name: 'accounts_products_products' })
      }
    }

    // 数据回显
    const { id } = route.query
    const loadDetail = async () => {
      loading.value = true
      const res = await getProductDetail({ id: Number(id) })
      loading.value = false
      if (res.success && res.result) {
        formData.value = formatProduct(res.result)
      }
    }
    if (id) {
      loadDetail()
    }

    // 展开
    // --------------------------------------------------------------
    const currentModuleKey = ref<string | null>('basicSetting')
    const handleUpdateExtended = (moduleKey, extended) => {
      const module = formData.value[moduleKey] || {}
      // 未禁用
      if (module.disabled !== true) {
        currentModuleKey.value = (extended ? moduleKey : null)
      }
    }

    // 禁用
    // --------------------------------------------------------------
    const handleUpdateEnabled = (module, enabled: boolean) => {
      if (module.disabled !== undefined) {
        module.disabled = !enabled
      }
    }

    return {
      loading,
      submitting,
      modules,
      mainForm,
      formData,
      proBusinessTypeEnum,
      handleSubmit,
      currentModuleKey,
      handleUpdateExtended,
      handleUpdateEnabled
    }
  }
})
